import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import "./hero.scss"

const Hero = ({ className, title, children }) => {
  return (
    <div className="hero-wrapper">
      <div className={classnames("hero", "max-width", className)}>
        {title && <h1 className="hero__title">{title}</h1>}
        {children}
      </div>
    </div>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Hero
