import React from "react"
import PropTypes from "prop-types"
import Col from "react-bootstrap/Col"
import classnames from "classnames"
import { Link } from "gatsby"
import learnImg from "../images/book.svg"
import dodImg from "../images/detection-on-demand.svg"
import helixImg from "../images/helix.svg"
import lighthouseImg from "../images/lighthouse.svg"
import messagebusImg from "../images/arrows-vertical-cloud.svg"
import pluginImg from "../images/plugin.svg"
import platformImg from "../images/gears.svg"
import endpointImg from "../images/endpoint.svg"
import "./api-portal.scss"

const images = {
  learn: learnImg,
  dod: dodImg,
  endpoint: endpointImg,
  lighthouse: lighthouseImg,
  messagebus: messagebusImg,
  plugin: pluginImg,
  platform: platformImg,
  helix: helixImg,
}
const APIPortal = ({
  image,
  title,
  description,
  toSpec,
  toDocs,
  urlSpec,
  urlDocs,
  toSpecText = "OpenAPI Spec",
  className,
  lg = 4,
}) => (
  <Col
    as="section"
    xs={12}
    lg={lg}
    className={classnames("api-portal", className)}
  >
    <img className="api-portal-image" src={images[image]} alt={title} />
    <h3>{title}</h3>
    <p>{description}</p>
    {className != "api-portal-coming-soon" && toSpec && !urlSpec && (
      <Link to={toSpec} className="btn">
        {toSpecText}
      </Link>
    )}
    {className != "api-portal-coming-soon" && urlSpec && !toSpec && (
      <a href={urlSpec} className="btn">
        {toSpecText}
      </a>
    )}
    {className != "api-portal-coming-soon" && toDocs && !urlDocs && (
      <Link to={toDocs} className="btn btn--hollow">
        Explore Docs
      </Link>
    )}
    {className != "api-portal-coming-soon" && urlDocs && !toDocs && (
      <a href={urlDocs} className="btn btn--hollow">
        Explore Docs
      </a>
    )}
    {className == "api-portal-coming-soon" && toSpec && !urlSpec && (
      <a className="btn noHover">{toSpecText}</a>
    )}
    {className == "api-portal-coming-soon" && urlSpec && !toSpec && (
      <a className="btn noHover">{toSpecText}</a>
    )}
    {className == "api-portal-coming-soon" && toDocs && !urlDocs && (
      <a className="btn btn--hollow noHover">Explore Docs</a>
    )}
    {className == "api-portal-coming-soon" && urlDocs && !toDocs && (
      <a className="btn btn--hollow noHover">Explore Docs</a>
    )}
  </Col>
)

APIPortal.propTypes = {
  image: PropTypes.oneOf([
    "learn",
    "dod",
    "endpoint",
    "lighthouse",
    "messagebus",
    "plugin",
    "platform",
    "helix",
  ]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  toSpec: PropTypes.string,
  toDocs: PropTypes.string,
  urlSpec: PropTypes.string,
  urlDocs: PropTypes.string,
  toSpecText: PropTypes.string,
  className: PropTypes.string,
  lg: PropTypes.object,
}

export default APIPortal
